import { Link } from "react-router-dom";
import Helmet from "react-helmet";

export default function Home() {
    
  return (
    <>
      <Helmet>
        <title>Dawg's Day NFT</title>
        <meta name="description" content="Dawg's Day NFT is a generative art project with the hopes of improving lives through a community centered around self-improvement and work-life balance." />

        <meta itemprop="name" content="Welcome | Dawg's Day NFT" />
        <meta itemprop="description" content="Dawg's Day NFT is a generative art project with the hopes of improving lives through a community centered around self-improvement and work-life balance." />
        <meta itemprop="image" content="http://www.dawgs.day/img/dawgs-day-og.png" />

        <meta property="og:url" content="http://dawgs.day" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Welcome | Dawg's Day NFT" />
        <meta property="og:description" content="Dawg's Day NFT is a generative art project with the hopes of improving lives through a community centered around self-improvement and work-life balance." />
        <meta property="og:image" content="http://www.dawgs.day/img/dawgs-day-og.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Welcome | Dawg's Day NFT" />
        <meta name="twitter:description" content="Dawg's Day NFT is a generative art project with the hopes of improving lives through a community centered around self-improvement and work-life balance." />
        <meta name="twitter:image" content="http://www.dawgs.day/img/dawgs-day-og.png" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      {/* Banner Section  */}
      <section className="hero-banner"></section>

      {/* About Section */}
      <section className="about-sec" id="about">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about-img">
                <img src="/img/about-img.jpg" />
              </div>
            </div>

            <div className="col-md-6">
              <div className="about-con">
                <h4>About</h4>
                <h2>Dawg's Day</h2>
                <p>
                  Dawg's Day is a generative set of 5,555 NFTs deployed on the Ethereum blockchain. The individual
                  Dawgs are minted through an ERC721A contract to help keep gas prices low when minting multiple
                  NFTs in a single purchase.
                </p>
                <p>
                  The project is a true utility NFT backed by real world brands owned by the project creator. We
                  have a lot of plans to involve the community directly with the brands, and we're excited to have
                  you with us. We'll be leaking small bits periodically until the grand reveal after we reach mint
                  3,333.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Roadmap Section  */}
      <section className="home-form" id="roadmap">
        <form>
          <div className="top-form">
            <div className="container">
              <div className="home-heading text-center">
                <div className="heading-img">
                  <img src="/img/dawgs-day-logo-secondary.png" />
                </div>
                <h2>Roadmap</h2>
              </div>

              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="form-box">
                    <div className="form-icon">
                      <img src="/img/form-icon1.png" />
                    </div>
                    <h3 className="text-center">Phase</h3>
                    <h4>Launch Preparation</h4>
                    <div className="form-details">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck1"
                          checked
                          disabled
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Website buildout
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck3"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck3"
                        >
                          Community building
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck2"
                        >
                          Allow-list building
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck4"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck4"
                        >
                          Internal art review
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck5"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck5"
                        >
                          Test run of mint contract
                        </label>
                      </div>
                    </div>
                    <div className="form-img">
                      <img src="/img/form-img1.png" />
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-12">
                  <div className="form-box">
                    <div className="form-icon">
                      <img src="/img/form-icon2.png" />
                    </div>
                    <h3 className="text-center">Phase</h3>
                    <h4>Project Mint</h4>
                    <div className="form-details">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck6"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck6"
                        >
                          Mint launch
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck7"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck7"
                        >
                          Utility teasers
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck8"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck8"
                        >
                          Collab &amp; partner building
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck9"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck9"
                        >
                          Team expansion
                        </label>
                      </div>
                    </div>
                    <div className="form-img">
                      <img src="/img/form-img2.png" />
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-12">
                  <div className="form-box">
                    <div className="form-icon">
                      <img src="/img/form-icon3.png" />
                    </div>
                    <h3 className="text-center">Phase</h3>
                    <h4>Milestone 3,333</h4>
                    <div className="form-details">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck10"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck10"
                        >
                          Reveal of utility
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck11"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck11"
                        >
                          Full data dump
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck12"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck12"
                        >
                          Team &amp; community scaling
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck13"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck13"
                        >
                          Utility scaling
                        </label>
                      </div>
                    </div>
                    <div className="form-img">
                      <img src="/img/form-img3.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-form">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-12">
                  <div className="bottom-form-box">
                    <h2>UNLEASH YOURSELF</h2>
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="bottom-form-box">
                    <a href="#roadmap" className="text-decoration-none">
                      SOON
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>

      {/* Team Section  */}
      <section className="home-team" id="team">
        <div className="container">
          <div className="home-heading text-center">
            <div className="heading-img">
              <img src="/img/dawgs-day-logo-secondary.png" />
            </div>
            <h2>TEAM</h2>
          </div>

          <div className="row">
            <div className="col-md-8 col-12 team-text-box">
              <div className="team-text">
                <p>
                  Project creator will be public information via doxd.io hopefully no later than January 16th, 2023.
                </p>
                <p>
                  The rest of the team is fluid and will evolve over the life of the project. This is intentional
                  and more will be revealed in later phases of the project.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-12 team-img-box">
              <div className="team-img">
                <img src="/img/doxd-icon.jpg" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Faq Section  */}
      <section className="home-faq" id="faq">
        <div className="container">
          <div className="home-heading text-center">
            <div className="heading-img">
              <img src="/img/dawgs-day-logo-secondary.png" />
            </div>
            <h2>Faqs</h2>
          </div>

          <div className="faq-details">
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link btn-block text-left"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What is the mint date?
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    The mint date is currently scheduled for Feb 11, 2023. Allow-list mint will start at
                    2:11:23 PM US Eastern Time and public mint will begin two hours later.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      What utility does this project offer?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      This project aims to improve the lives of all community members. This will be achieved by
                      connecting the community members with Web2 brands centered around self-improvement and
                      work-life balance. The entire holder community will have the opportunity to be involved
                      with the brands, and we hope to onboard some members as paid staff.
                    </p>
                    <p>
                      We have a lot to share but are operating in stealth until initial 3,333 mint target has
                      been reached.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingThree">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How many NFTs in total will there be?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    There will be a total of 5,555 generative art NFTs on the
                    Ethereum network.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingFour">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      What is the mint price?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    The public mint price is subject to change based on Ethereum market conditions. We are
                    currently expecting Allow-list mint price to be 0.025 ETH and public at 0.035 Ethereum.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingFive">
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Are holders granted ownership rights?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseFive"
                  className="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    Yes! You will have full ownership and intellectual property
                    rights of your NFTs for as long as you own them. This will
                    apply to both minted and secondary sales purchases. There
                    will be an official conveyance document released in the
                    future.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
