import Helmet from "react-helmet";
import {useEffect, useRef, useState} from "react";
const { ethers } = require('ethers')
const { MerkleTree } = require('merkletreejs')

export default function Allowlist() {
  const isMounted = useRef(true);
  const [state, setState] = useState({
    allowlistAddresses: [],
    allowlistAddressList: '',
    allowlistMerkleRoot: '',
    isLoading: false,
  });

  useEffect(() => {

    const getAddresses = async () => {
      const allowlistResponse = await fetch("https://dawgs.day/api/allowlist/addresses");
      const allowlistObject = await allowlistResponse.json();
      const allowlistAddressList = Object.keys(allowlistObject).map(key =>
          <li key={key}>{allowlistObject[key]}</li>
      );
      const { keccak256 } = ethers.utils;
      let leaves = allowlistObject.map((addr) => keccak256(addr));
      const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true });
      const merkleRootHash = merkleTree.getHexRoot();

      setState((prevState) => ({
        ...prevState,
        allowlistAddresses: allowlistObject,
        allowlistAddressList: allowlistAddressList,
        allowlistMerkleRoot: merkleRootHash
      }));
    };

    getAddresses();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
      <>
        <Helmet>
          <title>Allowlist | Dawg's Day NFT</title>
          <meta name="description" content="Dawg's Day NFT mint allowlist. Check for your wallet below to make sure you are on the allow-list for special mint pricing." />

          <meta itemprop="name" content="Allowlist | Dawg's Day NFT" />
          <meta itemprop="description" content="Dawg's Day NFT mint allowlist. Check for your wallet below to make sure you are on the allow-list for special mint pricing." />
          <meta itemprop="image" content="http://www.dawgs.day/img/dawgs-day-og.png" />

          <meta property="og:url" content="http://dawgs.day" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Allowlist | Dawg's Day NFT" />
          <meta property="og:description" content="Dawg's Day NFT mint allowlist. Check for your wallet below to make sure you are on the allow-list for special mint pricing." />
          <meta property="og:image" content="http://www.dawgs.day/img/dawgs-day-og.png" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Allowlist | Dawg's Day NFT" />
          <meta name="twitter:description" content="Dawg's Day NFT mint allowlist. Check for your wallet below to make sure you are on the allow-list for special mint pricing." />
          <meta name="twitter:image" content="http://www.dawgs.day/img/dawgs-day-og.png" />
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        {/* Banner Section  */}
        <section className="hero-banner"></section>

        {/* About Section */}
        <section className="about-sec" id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-2">

              </div>
              <div className="col-md-8">
                <div className="about-con">
                  <h4 className="text-center">
                    Mint Allowlist
                  </h4>
                  <p className="text-center">Make sure your address is on the allow-list by Feb 9th.</p>

                  <div className="col mt-5 p-5 bg-primary-yellow">
                    <p>Full List</p>
                    <ul className="pb-3">
                      { state.allowlistAddressList }
                    </ul>
                    <p className="pb-3">
                      CSV
                      <textarea className="mt-2 w-full text-sm" rows="3" defaultValue={state.allowlistAddresses.join(',')} />
                    </p>
                    <p className="pb-3">
                      Merkle Root
                      <input type="text" className="w-full text-sm" defaultValue={state.allowlistMerkleRoot} />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">

              </div>
            </div>
          </div>
        </section>
      </>
  );
}
